<template>
	<div v-cloak id="Portal" :class="{ ready: ready }">
		<!--Toolbar-->
		<Toolbar :userConfig="userConfig" :clientConfig="clientConfig" />
		<!--Portal Content-->
		<div id="portalContent" :class="portalContentClass" v-if="client.browser.supported">
			<!--Body-->
			<div id="portalBody">
				<div id="bodyContent" v-scroller>
					<div>
						<component
							ref="currentApp"
							:is="appComponent"
							:userApp="userApps[currentApp.name]"
							@mounted="currentApp.mounted = true"
						/>
					</div>
				</div>
				<portal-target name="dialog"></portal-target>
				<!--User Lock -->
				<component id="userLock" v-if="userLock" :is="lockComponent" :appConfig="userApps" />
			</div>
			<!--Menu-->
			<Menu :menuState="menuState" :appMenus="appMenus" />
			<Loader class="background top" v-if="loader" />
		</div>
		<!--Browser Not Supported -->
		<BrowserNotSupported
			v-else
			:browser="client.browser"
			:browserSupport="$globalStatics.portalConfig.browserSupport"
		/>
		<!--Drawer-->
		<transition name="fadeInOut">
			<div v-if="drawerOpen" id="drawer" :class="drawerClass">
				<transition name="replaceWithFade">
					<!--Drawers-->
					<portalSettings v-if="drawers.settings"></portalSettings>
					<UserSettings v-if="drawers.user"></UserSettings>
					<div v-if="drawers.help">&nbsp;</div>
				</transition>
			</div>
		</transition>
		<!--notification-->
		<NotificationPanel id="notify" />
	</div>
</template>

<script>
import Vue from "vue";

//--global registration:
//mixin
import globalMixin from "./globalMixin"; //set up $globalStatics and $icons (static)
//directives
import Scrollbar from "../Tools/scrollerDirective";
Vue.use(Scrollbar);
import Tooltip from "../Tooltip/tooltipDirective";
Vue.use(Tooltip);

function browserLocale() {
	return (
		navigator.userLanguage ||
		(navigator.languages && navigator.languages.length && navigator.languages[0]) ||
		navigator.language ||
		navigator.browserLanguage ||
		navigator.systemLanguage ||
		"en"
	);
}

//global $fetch function
import fetch from "./fetch";
//setup fetch
Vue.prototype.$fetch = function () {
	return fetch.post;
};
Vue.prototype.$post = async function () {
	let res = await fetch.post(...arguments);
	return res;
};
Vue.prototype.$get = async function () {
	return await fetch.get(...arguments);
};
Vue.prototype.$put = async function () {
	return await fetch.put(...arguments);
};
Vue.prototype.$patch = async function () {
	return await fetch.patch(...arguments);
};
Vue.prototype.$fetchDelete = async function () {
	return await fetch.delete(...arguments);
};
Vue.prototype.$getServerBuild = async function () {
	return await fetch.serverBuild();
};
Vue.prototype.$utils = require("@SyoLab/utils");
Vue.prototype.$la;

import * as portalHelpers from "./portalHelpers";
Vue.prototype.$displayUpdate = portalHelpers.displayUpdate;

// portal store (pinia)
import portalStateP from "./portalState";
import { mapActions, mapState, mapWritableState } from "pinia";

//css
import "../css/reset.css";
import "../css/normalize.css";
import "../css/global.css";

//modules
import Browser from "bowser";
import eventBus from "./eventBus";
import * as menuFn from "./menuFn";

//config
import config from "@/config.js";

//Icons
import { ReturnToSession } from "@icons/appFabric/icons";

//components
import AppLoading from "./ErrorPages/AppLoading";
import AppLoadingError from "./ErrorPages/AppLoadingError";
import AppLoadingTimeout from "./ErrorPages/AppLoadingTimeout.vue";
import BrowserNotSupported from "./ErrorPages/BrowserNotSupported";
import Toolbar from "./Toolbar/Toolbar";
import Menu from "./Menu";
import NotificationPanel from "./NotificationPanel";
import Loader from "@components/Tools/Loader";
let listeners = [];
export default {
	name: "Portal",
	mixins: [globalMixin],
	components: {
		Toolbar,
		Menu,
		NotificationPanel,
		BrowserNotSupported,
		Loader,
	},
	props: {
		options: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			//build
			serverBuild: {
				version: undefined,
				buildTime: undefined,
				build: undefined,
			},
			//browser client
			client: null,
			clientConfig: {},
			//apps available in portal
			portalApps: {},
			//currentApp
			currentApp: {
				name: undefined,
				component: null,
				auth: null,
				mounted: false,
				delay: false,
				timeout: false,
				error: false,
				initialized: false,
			},
			drawers: {
				user: false,
				settings: false,
				help: false,
			},
			ready: false,
			startup: true,
			outsideClickWatches: [],
			menuState: {
				expanded: true,
				isAnimating: false,
			},
			loader: false,
			// locales
			locales: [], // list of available locales
			// viewport: vh, vw
			height: null,
			width: null,
		};
	},
	computed: {
		...mapWritableState(portalStateP, ["authenticated", "userConfig", "userApps", "locale"]),
		appComponent() {
			//copmponent is not set (loaded)
			if (this.currentApp.component == null) {
				// => show AppLoadingTimeouts;
				if (this.currentApp.error) return AppLoadingError;
				if (this.currentApp.timeout) return AppLoadingTimeout;
				if (this.currentApp.delay) return AppLoading;
				return undefined;
			}
			//component ist loaded and set
			//do we need authentication?
			if (this.currentApp.auth) {
				//if app is authenticated or was mounted before => show
				if (this.authenticated || this.currentApp.mounted) {
					// lastCheck: has user this App enabled in Config?
					if (this.userAppEnabled(this.currentApp.name) == false) return undefined;
					return this.currentApp.component;
				} else {
					return undefined;
				}
			}
			//authentication is not required => show
			return this.currentApp.component;
		},
		lockComponent() {
			if (this.$globalStatics.portalConfig.lockApp) {
				return this.portalApps[this.$globalStatics.portalConfig.lockApp].component;
			}
			return null;
		},
		userLock() {
			//if no current App => do not lock
			if (!this.currentApp.name) return false;
			//if current App needs no authentication => do not lock
			if (this.currentApp.auth != true) return false;
			//we need authentication:
			//if authentication was never tested
			//  => do not lock and wait until the request finishes in background
			//        (is triggered on loading)
			if (this.authenticated === undefined) return false;
			//authentication was done => set state of authentication
			return !this.authenticated;
		},
		appMenus() {
			let apps = [];
			//extract all menu[] from app config
			//filter apps with menus to display
			for (const key in this.portalApps) {
				let app = this.portalApps[key];
				if (menuFn.appMenuShow(this, key)) {
					apps.push(app);
				}
			}
			//sort by menuPosition
			apps = this.$utils.array.objectSort(apps, "menuPosition");

			//get menus from apps
			let menus = [];
			apps.forEach((app) => {
				let appMenu = {
					appName: app.name,
					show: true,
					active: this.currentApp.name == app.name ? true : false,
					menus: menuFn.menuImport(app.menu, app.name, this.userApps[app.name]),
				};
				menus.push(appMenu);
			});

			return menus;
		},
		defaultApp() {
			return this.userConfig.defaultApp ? this.userConfig.defaultApp : this.$globalStatics.portalConfig.defaultApp;
		},
		portalContentClass: function () {
			let hasMenu = this.appMenus != undefined && this.appMenus.length > 0;
			return {
				menu: hasMenu,
				menuExpanded: this.menuState.expanded,
				isAnimating: this.menuState.isAnimating,
			};
		},
		drawerOpen: function () {
			//checks if any of the modules are true
			let ret = false;
			for (const key in this.drawers) {
				if (this.drawers[key]) {
					ret = true;
					break;
				}
			}
			return ret;
		},
		drawerClass: function () {
			let ret = "";
			for (const key in this.drawers) {
				if (this.drawers[key]) {
					ret = key;
					break;
				}
			}
			return ret;
		},
		appMargins() {
			return {
				top: 64,
				left: this.menuState.expanded ? 200 : 50,
			};
		},
		isMoblie() {
			if (this.width && this.width < 900) return true;
			return false;
		},
		navPath() {
			return this.$route.path.split("/").filter((item) => item);
		},
	},
	methods: {
		setCurrentApp: async function (appName) {
			console.log("setCurrentApp", appName, this.defaultApp);
			//set first or default if appName does not exitst or is not set
			if (appName == null || appName == "" || appName == "default") {
				// set route for default app
				this.$router.push(this.portalApps[this.defaultApp].menu[0].route);
				return;
			}

			//return if no change
			if (this.currentApp.name == appName) {
				return;
			}

			//app enabled / configured?
			if (!this.portalApps[appName]) {
				this.currentApp.error = true;
				console.error("App not found");
				return;
			}

			//initialize before loading
			this.currentApp.component = undefined;
			this.currentApp.auth = undefined;
			this.currentApp.loaded = false;
			this.currentApp.delay = false;
			this.currentApp.timeout = false;
			this.currentApp.error = false;
			this.currentApp.initialized = false;
			//show load delay after config
			setTimeout(() => {
				this.currentApp.delay = true;
			}, this.$globalStatics.portalConfig.loadDelay);
			//show timeout if loading goes wrong
			setTimeout(() => {
				this.currentApp.timeout = true;
			}, this.$globalStatics.portalConfig.loadTimeout);

			//load app
			try {
				// set app.name
				this.currentApp.name = appName;
				// check authentication is needed
				this.currentApp.auth = this.portalApps[appName].auth == false ? false : true;
				// check if we need to authenticate
				if (this.currentApp.auth) {
					//throws error for not authenticated
					this.authenticated = await fetch.authorize();
					// check if current App is enabled
					if (this.authenticated && !this.userAppIsEnabled()) {
						this.currentApp.error = true;
						return;
					}
				}

				// if a paralell set App is ongoing (this.currentApp.name != appName)) => do not set component
				if (this.currentApp.name == appName) {
					this.currentApp.component = this.portalApps[appName].component;
				}
			} catch (error) {
				console.error("app Loading Error", error);
				this.currentApp.error = true;
				return;
			}

			//app loaded
		},
		userAppIsEnabled() {
			let appName = this.currentApp.name;
			if (!this.userApps[appName]) return false;
			if (typeof this.userApps[appName].enabled != "undefined") {
				return this.userApps[appName].enabled === true ? true : false;
			}
			return this.userApps[appName] ? true : false;
		},

		/**
		 * drawer events (close/toggle)
		 */
		onDrawer: function (event) {
			if (!event) return;
			event = event.split(".");

			//close active drawer module
			if (event[0] == "close") {
				for (const key in this.drawers) {
					this.drawers[key] = false;
				}
			}
			//toggle a drawer module
			if (event[0] == "toggle" && event[1]) {
				this.drawers[event[1]] = !this.drawers[event[1]];
				//if toggle to true -> set all others to fals
				if (this.drawers[event[1]]) {
					for (const key in this.drawers) {
						if (key != event[1]) this.drawers[key] = false;
					}
				}
			}
		},
		loadConfig() {
			//import portal config
			for (const key in config.portal) {
				this.$globalStatics.portalConfig[key] = config.portal[key];
			}
			this.$globalStatics.portalConfig.buildCheckInterval = config.buildCheckInterval;
			// set version
			this.$globalStatics.portalConfig.version = config.version;

			//detect browser and browser-support
			this.client = Browser.parse(window.navigator.userAgent);
			const browser = Browser.getParser(window.navigator.userAgent);
			let isRip, isNotSupported;
			if (this.$globalStatics.portalConfig.browserSupport.rip) {
				isRip = browser.satisfies(this.$globalStatics.portalConfig.browserSupport.rip);
			}
			if (this.$globalStatics.portalConfig.browserSupport.notSupported) {
				isNotSupported = browser.satisfies(this.$globalStatics.portalConfig.browserSupport.notSupported);
			}
			this.client.browser.supported = isRip ? false : isNotSupported ? false : true;

			// detect credentialAPI
			this.$globalStatics.portalConfig.credentialAPI = browser.satisfies(
				this.$globalStatics.portalConfig.credentialApiSupport
			);

			// locales
			this.$set(this, "locales", config.portal.languages);
		},
		getAppRoutes() {
			this.portalApps;
			//add catch all route
			let routes = [
				{
					name: "default",
					path: "*", //404 catch all (default)
					meta: { appName: "default", notfoundRoute: true },
				},
			];

			//extract all routes[] from app config
			for (const key in this.portalApps) {
				let app = this.portalApps[key];
				if (app.routes) {
					let appRoutes = portalHelpers.addRoutesMetaData(app.routes, { appName: app.name });
					routes = routes.concat(appRoutes);
				}
			}

			return routes;
		},
		setLocale(locale) {
			if (locale) locale = locale.split("-")[0];
			this.locale = locale;
			this.$i18n.locale = locale;
		},
		async buildCheck() {
			let res = await this.$getServerBuild();

			let hasChanges = false;
			for (const key in res) {
				if (this.serverBuild[key] != res[key]) hasChanges = true;
			}
			console.log("buildcheck", hasChanges, res);
			if (hasChanges) this.serverBuild = res;
		},
		userAppEnabled: function (appName) {
			if (!this.userApps[appName]) return false;
			if (this.userApps[appName].disabled) return false;
			return true;
		},
	},
	globalStatics() {
		//setup config and routes
		return {
			//portal config, loaded from config
			portalConfig: {
				companyName: undefined,
				title: undefined,
				apps: [],
				lockApp: undefined,
				defaultApp: undefined,
				api: {},
				loadDelay: 200, // show Delay after
				loadTimeout: 5000, // show Timeout after
				browserSupport: {
					//list not supported browser
					rip: null,
					notSupported: null,
				},
				credentialApiSupport: {}, //list browser with credential API
				credentialAPI: false, // credentialAPI flag
				outsideClickMouseDownEl: null,
			},
		};
	},
	created() {
		//reference fetch Options
		fetch.setPortalRef(this);
		this.setLocale(browserLocale());
		//load config
		this.loadConfig();

		let portalConfig = this.$globalStatics.portalConfig;
		if (portalConfig.buildCheckInterval && !isNaN(portalConfig.buildCheckInterval)) {
			let buildCheckInterval = portalConfig.buildCheckInterval * 60 * 1000;
			this.buildCheck();
			setInterval(() => {
				this.buildCheck();
			}, buildCheckInterval);
		}
	},
	beforeMount() {
		//set events
		listeners.push(
			eventBus.on("portal.loader", (value) => {
				this.loader = value;
			})
		);
		listeners.push(
			eventBus.on("userConfig.set", (config) => {
				this.userConfig = config;
				this.userConfig.locale = config.locale ? config.locale : browserLocale();
				this.setLocale(this.userConfig.locale);
			})
		);
		listeners.push(
			eventBus.on("clientConfig.set", (config) => {
				this.clientConfig = config;
				this.menuState.expanded = typeof config.menuExpanded == "undefined" ? true : config.menuExpanded;
			})
		);
		listeners.push(
			eventBus.on("userApps.set", (config) => {
				this.userApps = config;
			})
		);
		listeners.push(
			eventBus.on("auth.authenticated", (value) => {
				this.authenticated = value;
				if (value) {
					//default route might habe changed -> reload
					let appName = this.$route.meta.appName;
					// check if App is available fro this user
					if (!this.userApps[appName]) {
						appName = this.userConfig.defaultApp || this.defaultApp;
					}
					this.setCurrentApp(appName);
				}
			})
		);
		listeners.push(
			eventBus.on("auth.stayLoggedIn", (value) => {
				this.clientConfig.stayLoggedIn = value;
			})
		);
		listeners.push(
			eventBus.on("auth.logoff", () => {
				//wipe userConfig
				Object.keys(this.userConfig).forEach((key) => {
					this.userConfig[key] = undefined;
				});
				//wipe userApps
				this.userApps = {};
				this.authenticated = false;
				//redirect to default app (root)
				this.$router.push("/");
			})
		);
		listeners.push(
			eventBus.on("menu.click", async (menuItem) => {
				let targetPath = menuItem.route;
				let currentPath = this.$route.path;

				if (currentPath !== targetPath) {
					this.$router.push({ path: targetPath }, async () => {
						await this.$displayUpdate();
					});
				}
				if (typeof this.$refs.currentApp.onMenuClick == "function") {
					this.$refs.currentApp.onMenuClick(menuItem.value);
				}
			})
		);
		listeners.push(
			eventBus.on("menu.toggle", (value) => {
				this.menuState.isAnimating = true;
				setTimeout(() => {
					this.menuState.expanded = value.expanded;
					setTimeout(() => {
						this.menuState.isAnimating = false;
					}, 500);
				}, 2);
			})
		);
		// global click handler (used for outside-click detection)
		function clickHandler(e) {
			this.$emit("documentTouchClick", e, this.outsideClickMouseDownEl);
		}

		function downHandler(e) {
			this.outsideClickMouseDownEl = e.target;
		}
		document.removeEventListener("mousedown", downHandler.bind(this));
		document.removeEventListener("click", clickHandler.bind(this));
		document.removeEventListener("touchend", clickHandler.bind(this));
		document.removeEventListener("touchstart", downHandler.bind(this));

		document.addEventListener("mousedown", downHandler.bind(this));
		document.addEventListener("click", clickHandler.bind(this));
		// document.addEventListener("touchend", clickHandler.bind(this));
		document.addEventListener("touchstart", downHandler.bind(this));
	},
	async mounted() {
		//async import apps config
		let apps = {};
		for (const app of config.portal.apps) {
			//eager: modules are included in the current chunk
			// => all configs are include in this chunk
			let appConfig = await import(/* webpackMode: "eager" */ `@/apps/${app}/app.js`);
			apps[app] = appConfig.default;
		}

		this.portalApps = Object.assign({}, this.portalApps, apps);
		//set routes
		this.$router.addRoutes(this.getAppRoutes());
		//set ready to start fade-in
		this.$nextTick(function () {
			this.ready = true;
		});
		// resizeObserver
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.contentBoxSize) {
					// Firefox implements `contentBoxSize` as a single content rect, rather than an array
					const contentBoxSize = Array.isArray(entry.contentBoxSize)
						? entry.contentBoxSize[0]
						: entry.contentBoxSize;
					this.width = contentBoxSize.inlineSize;
					this.height = contentBoxSize.blockSize;
				} else {
					this.height = entry.contentRect.height;
					this.width = entry.contentRect.width;
				}
			}
		});
		resizeObserver.observe(this.$el);
	},
	beforeDestroy() {
		this.$off();
		listeners.forEach((listener) => {
			listener.off();
		});
	},

	watch: {
		$route(to, from) {
			if (to.meta.notfoundRoute) {
				console.error("route not found, diverting to default", to);
			}
			this.setCurrentApp(to.meta.appName);
		},
		serverBuild(value, oldValue) {
			//no serverBuild in dev-mode or broken connection
			if (!this.serverBuild.build) return;
			if (!this.$globalStatics.portalConfig.version) return;

			if (this.serverBuild.build != this.$globalStatics.portalConfig.version.build) {
				console.log("new version");
				setTimeout(() => {
					if (this.serverBuild.build != this.$globalStatics.portalConfig.version.build) {
						window.location.reload(true);
					}
				}, 3000);
			}
		},
	},
};
</script>

<style>
	[v-cloak] {
		display: none;
	}
	* {
		box-sizing: border-box;
	}

	body {
		font-family: "Roboto", "Segoe", -apple-system, BlinkMacSystemFont, "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans",
			"Helvetica Neue", sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.5;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		color: #323130;
		margin: 0;
		overflow: hidden;
	}
	#Portal {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		position: relative;
	}
	#Portal.ready {
		opacity: 1;
		transition: opacity 300ms ease-in-out;
	}
	#portalContent {
		width: 100vw;
		height: calc(100vh - 64px);
		position: relative;
		display: flex;
	}

	#bodyContent {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}

	/* Portal Body */
	#portalBody {
		padding: 0;
		height: 100%;
		width: 100%;
		position: relative;
	}
	#Portal.ready #portalContent.isAnimating #portalBody {
		transition: margin-left, width;
		transition-duration: 0.5s;
		transition-timing-function: ease;
	}
	#portalContent.menu #portalBody {
		margin-left: 50px;
		max-width: calc(100vw - 50px);
		width: calc(100vw - 50px);
	}
	#portalContent.menu.menuExpanded #portalBody {
		margin-left: 200px;
		max-width: calc(100vw - 200px);
		width: calc(100vw - 200px);
	}

	#portalContent.menu.menuExpanded .Menu {
		width: 200px;
	}

	#Menu {
		z-index: 100;
	}

	/* Body Lock */
	#userLock {
		z-index: 5;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
	}

	#drawer {
		position: fixed;
		background-color: white;
		box-shadow: -4px 0px 3px -3px rgba(0, 0, 0, 0.2);
		padding: 10px 20px 10px 20px;
		height: calc(100vh - 64px);
		right: 0;
		top: 64px;
		width: 320px;
		transition: width 0.5s ease-in-out;
		overflow: hidden;
	}
	#drawer.help {
		width: 800px;
	}
	#notify {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	/* */

	/* replaceWithFade*/
	.replaceWithFade-enter {
		visibility: hidden;
		opacity: 0;
	}
	.replaceWithFade-enter-to {
		visibility: visible;
		opacity: 1;
	}
	.replaceWithFade-enter-active {
		transition: visibility 0s linear 0.3s, opacity 0.5s ease 0.3s;
	}
	.replaceWithFade-leave {
		opacity: 1;
	}
	.replaceWithFade-leave-to {
		opacity: 0;
	}
	.replaceWithFade-leave-active {
		transition: opacity 0.3s;
	}

	@font-face {
		font-family: "Roboto";
		src: url("../font/Roboto-Thin.ttf") format("woff");
		font-weight: 100;
		font-style: normal;
	}
	@font-face {
		font-family: "Roboto";
		src: url("../font/Roboto-Light.ttf") format("woff");
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: "Roboto";
		src: url("../font/Roboto-Regular.ttf") format("woff");
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: "Roboto";
		src: url("../font/Roboto-Medium.ttf") format("woff");
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: "Roboto";
		src: url("../font/Roboto-Bold.ttf") format("woff");
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: "Radikal";
		src: url("../font//Radikal-Medium.woff") format("woff");
		font-style: normal;
	}
</style>
