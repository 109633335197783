import Vue from 'vue'
Vue.prototype.$globalStatics = {}
Vue.prototype.$icons = {}

/**
 * 
 * how to use static data:
 * 
 .vue template:

 export default {
  data: function(){
      return {}
  },

  //set up static data in template
  static: () => ({
    static_value: 'Vue is awesome'
  }),

  created () {
    console.log(this.$globalStatics.static_value); // Vue is awesome
  }
}

 */


export default {
	beforeCreate() {
		//setup $globalStatics 
		const $static = this.$options.globalStatics
		const $static_destination = this.$globalStatics || this

		if ($static && typeof ($static) === 'function') {
			Object.assign($static_destination, $static.apply(this))
		} else if ($static && typeof ($static) === 'object') {
			Object.assign($static_destination, $static)
		}
		//setup $icons (static)
		const $icons = this.$options.icons
		const $icons_destination = this.$icons || this
		if ($icons && typeof ($icons) === 'function') {
			Object.assign($icons_destination, $icons.apply(this))
		} else if ($icons && typeof ($icons) === 'object') {
			Object.assign($icons_destination, $icons)
		}
	}
}