import utils from "@SyoLab/utils"
import { Settings } from "../../@icons/appFabric/icons"
const deployApp = () => import(/* webpackChunkName: "SiteApp" */"./Site.vue")
const appName = 'site'
export default {
   name: appName,
   auth: true, //auth: if authenticated must have app in userApps
   menuShow: false, //menuShow overrides everything
   menuPosition: 100, //sorting menu position of app
   component: deployApp,
   routes: [
      {
         path: "/site"
      }
   ],
   menu: [
      {
         "_id": utils.shortId(),
         "appName": appName,
         "text": "Settings",
         "class": "siteClass",
         "route": "/site", //route path
         "action": undefined, //action emitted to app
         "active": true,
         "show": true,
         "icon": Settings
      }
   ]
}