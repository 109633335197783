import Vue from 'vue'
import Portal from "@components/Portal/Portal";
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({
	mode: 'history'
});

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Vue.config.productionTip = false

Vue.mixin({
	created: function () {
		//injects global $portal reference
		if (this.$options.name == "Portal") {
			this.$portal = this;
		}
		if (this.$parent && this.$parent.$portal) {
			this.$portal = this.$parent.$portal;
		}

	}
});

import i18n from './i18n'
import pinia from "./pinia"

new Vue({
	render: h => h(Portal),
	pinia,
	i18n,
	router
}).$mount('#app')