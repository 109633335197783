import { defineStore } from "pinia";
import Vue from 'vue'

/**
 * Hot Import:
 * 
   import portalStateP from "@components/Portal/portalState"
   const portalState = portalStateP()
 * 
 */


function browserLocale() {
   return (
      navigator.userLanguage ||
      (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      "en"
   );
}

export default defineStore({
   id: "portalState",
   state() {
      return {
         // user
         authenticated: null,
         userConfig: {
            userId: null,
            firstName: null,
            lastName: null,
            stayLoggedIn: null,
            ttl: null,
            defaultApp: null,
            locale: browserLocale(),
            manager: null,
            persNo: null,
            orgUnit: null
         },
         userApps: {},
         locale: null, // active locale == this.i18n.locale
      }
   },
   getter: {

   },
   actions: {

   }
});