<template>
	<div class="Popover" :style="style_merged">
		<slot></slot>
	</div>
</template>

<script type="text/javascript">
export default {
	name: "Popover",
	props: {
		/**
		 * Where this Component is trasfered to after mount
		 * If not set, the Element will not be transfered
		 */
		anchorEl: {
			type: [Object, String, HTMLElement],
			required: false,
			default: null,
			note: "Provide Dom El or querystring",
		},
		/**
		 * Reference to position element to
		 */
		refEl: {
			type: [Object, String, HTMLElement],
			required: false,
			default: null,
		},
		/**
		 * Style Object to apply to Popover
		 */
		templateStyles: {
			type: [Object],
			required: false,
			default: () => {
				return {};
			},
		},
		/**
		 * Offset Object (top/left)
		 */
		offset: {
			type: [Object],
			required: false,
			default: () => {
				return {};
			},
		},
		/**
		 * vertical orientation in relation to th refEl
		 * @values top, middle, bottoom
		 */
		orientation: {
			type: String,
			required: false,
			default: "bottom",
		},
		/**
		 * horizontal orientation in relation to th refEl
		 * @values left, center, right
		 */
		align: {
			type: String,
			required: false,
			default: "left",
		},
	},
	data: function () {
		return {
			anchorNode: null,
			refElNode: null,
			top: null,
			left: null,
			scrollbarComponent: null,
			orientations: [],
		};
	},
	computed: {
		style_merged: function () {
			let style = {
				...this.templateStyles,
				top: this.top,
				left: this.left,
			};
			if (this.refEl) {
				style.top = this.top;
				style.left = this.left;
			}
			if (!style.top) delete style.top;
			if (!style.left) delete style.left;
			return style;
		},
	},
	created: function () {
		this.orientations = this.orientation.split(",").map((item) => {
			return item.trim();
		});
		this.aligns = this.align.split(",").map((item) => item.trim());
	},
	mounted: function () {
		if (this.anchorEl) {
			//set anchor el if set in props:
			this.anchorNode = this.getEl(this.anchorEl);
			this.refElNode = this.getEl(this.refEl);

			//transfer to anchor
			this.anchorNode.append(this.$el);
			if (this.refEl) this.setPos();
		}

		//add event listener
		setTimeout(() => {
			this.$portal.$on("documentTouchClick", (e, down) => {
				if (this.$el && this.$el.contains(down)) return;
				this.$emit("outsideClick", e);
			});
		}, 1);
	},
	beforeDestroy: function () {
		this.$portal.$off();
		this.anchorNode = null;
		this.refElNode = null;
		this.top = null;
		this.left = null;
	},
	methods: {
		//get element from property
		getEl: function (ref) {
			//by string
			if (typeof ref == "string") {
				let el = document.querySelectorAll(ref);
				return el.length > 0 ? el[0] : null;
			}
			//by element
			if (typeof ref == "object" && ref instanceof HTMLElement) {
				return ref;
			}
		},
		setPos: function () {
			//get coordinates refEl relative to anchor
			let { top, left } = this.getCoord(this.refElNode, this.getCoord(this.anchorNode));
			//adjust with offset
			top = this.offset && this.offset.top ? top + this.offset.top : top;
			left = this.offset && this.offset.left ? left + this.offset.left : left;
			({ top, left } = this.adjustByOrientation(top, left));
			({ top, left } = this.adjustByAlign(top, left));
			this.top = top + "px";
			this.left = left + "px";
		},
		getCoord: function (elem, offset) {
			var box = elem.getBoundingClientRect();
			var body = document.body;
			var docEl = document.documentElement;
			var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
			var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
			var clientTop = docEl.clientTop || body.clientTop || 0;
			var clientLeft = docEl.clientLeft || body.clientLeft || 0;
			var top = box.top + scrollTop - clientTop;
			var left = box.left + scrollLeft - clientLeft;
			if (offset) {
				if (offset.top) top -= offset.top;
				if (offset.left) left -= offset.left;
			}
			return { top: Math.round(top), left: Math.round(left) };
		},
		adjustByOrientation(top, left) {
			if (this.orientation) {
				//get box of refEl
				var box = this.refElNode.getBoundingClientRect();
				//adjust for bottom
				if (this.orientation == "bottom") {
					top += box.height;
				}
				//adjust for top
				if (this.orientation == "top") {
					let elBox = this.$el.getBoundingClientRect();
					top -= elBox.height;
				}
			}
			return { top, left };
		},
		adjustByAlign(top, left) {
			if (this.align) {
				let aligns = this.align.split(" ");
				var refBox = this.refElNode.getBoundingClientRect();
				//adjust to horizontal align right
				if (aligns.includes("right")) {
					let elBox = this.$el.getBoundingClientRect();
					left = left + refBox.width - elBox.width;
				}
				//adjust to horizontal align center
				if (aligns.includes("center")) {
					let elBox = this.$el.getBoundingClientRect();
					left = left + refBox.width / 2 - elBox.width / 2;
				}
				if (aligns.includes("middle")) {
					let elBox = this.$el.getBoundingClientRect();
					left = left + refBox.height / 2 - elBox.width / 2;
				}
				if (aligns.includes("top")) {
					let elBox = this.$el.getBoundingClientRect();
					left = left + refBox.height + elBox.width;
				}
			}
			return { top, left };
		},
	},
};
</script>

<style scoped>
	.Popover {
		position: absolute;
	}
</style>

<docs>
The Popover transfers on mount to a new location.

- `anchorEl` is where the Popover is attached to  
- `refEl` is the position reference when positioning the Popover

</docs>
