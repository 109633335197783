<template>
	<div class="Input form" :class="{ focus: hasFocus, readonly: settings.readonly || settings.disabled }">
		<div class="wrapper" @click="onInputClick">
			<div v-if="$slots.before" class="before">
				<slot class="before" name="before"></slot>
			</div>
			<div v-if="settings.before" class="beforeText">{{ settings.before }}</div>
			<input
				:autocomplete="settings.autocomplete"
				:name="inputName"
				:id="inputId"
				:type="inputType"
				:readonly="settings.readonly"
				:disabled="settings.disabled"
				:spellcheck="settings.spellcheck"
				:placeholder="placeholderText"
				:value="displayValue"
				:inputmode="inputmodeValue"
				@focus="onFocus"
				@blur="onBlur"
				@input="onInput($event.target.value)"
				@keyup.enter="onEnter"
				@keypress="onKeypress"
				ref="input"
			/>
			<div class="pwMask" v-if="type == 'password'" @click="pwMask = !pwMask">
				<Icon :icon="pwMask ? iconHidden : iconHide" />
			</div>
			<div v-if="$slots.after" class="after">
				<slot name="after"></slot>
			</div>
			<div v-if="settings.clearIcon" class="afterText">
				<Icon class="clearIcon" :icon="iconClear" @click.stop="onClear" />
			</div>
			<div v-if="settings.after" class="afterText">{{ settings.after }}</div>
		</div>
		<div class="notification" :class="{ error: settings.notificationIsError }">{{ settings.notification }}</div>
	</div>
</template>

<script>
import utils from "@SyoLab/utils";
// auto-width: https://css-tricks.com/auto-growing-inputs-textareas/
import Icon from "@components/Tools/Icon";
import { RedEye, Hide, ChromeClose } from "@icons/appFabric/icons.js";
export default {
	name: "Input",
	components: { Icon },
	props: {
		label: {
			type: String,
			required: false,
			default: "",
		},
		value: {
			required: false,
			default: null,
		},
		readonly: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		disabled: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		notification: {
			type: String,
			required: false,
		},
		notificationIsError: {
			type: Boolean,
			required: false,
			default: false,
		},
		type: {
			type: String,
			required: false,
			default: "text",
		},
		spellcheck: {
			type: String,
			required: false,
			default: "false",
		},
		autocomplete: {
			type: String,
			required: false,
			default: "new-password",
		},
		options: {
			type: Object,
			required: false,
			default: function () {
				return {};
			},
		},
		placeholder: {
			type: String,
			required: false,
		},
		mask: {
			required: false,
			type: String,
		},
		maskShow: {
			required: false,
		},
		inputmode: {
			required: false,
		},
	},
	data() {
		return {
			hasFocus: false,
			inputData: null,
			iconHidden: RedEye,
			iconHide: Hide,
			iconClear: ChromeClose,
			pwMask: true,
			uID: utils.shortId(),
			tokens: {
				"#": { pattern: /\d/ },
				X: { pattern: /[0-9a-zA-Z]/ },
				S: { pattern: /[a-zA-Z]/ },
				A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
				a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
				"!": { escape: true },
			},
			dynamicMask: null,
			separatorDecimal: null,
			displayValue: null,
			componentValue: null,
		};
	},
	methods: {
		focus() {
			this.$refs.input.focus();
		},
		onBlur(event) {
			this.hasFocus = false;
			this.$emit("blur", event.target.value);
		},
		onFocus(event) {
			if (this.settings.readonly || this.settings.disabled) {
				event.preventDefault();
				return;
			}

			if (this.settings.selectOnFocus && this.displayValue && this.displayValue.length > 0) {
				this.$refs.input.setSelectionRange(0, this.displayValue.length);
			}
			this.hasFocus = true;
			this.$emit("focus", event.target);
		},
		onEnter(event) {
			let eventValue = event.target.value;
			// if maks is set:
			if (this.settings.mask) {
				eventValue = this.maskNumberGetValue(eventValue);
			}
			this.$emit("enter", this.formatTo(eventValue));
		},
		onInputClick(event) {
			this.$emit("click", event);
		},
		onInput(value, emit = true) {
			let displayValue = value;
			let componentValue = value;

			// if maks is set:
			if (this.settings.mask) {
				if (value) {
					let { unmasked, masked } = this.maskValue(String(value));
					displayValue = masked;
					componentValue = this.settings.maskReturnValue == "unmasked" ? unmasked : masked;
				} else {
					displayValue = value;
					componentValue = value;
				}
			}

			this.displayValue = displayValue;
			this.setAutoWidthText(this.displayValue);

			if (this.componentValue != componentValue) {
				this.componentValue = componentValue;
				if (emit) {
					this.$emit("input", this.formatTo(this.componentValue));
					this.$emit("change", this.formatTo(this.componentValue));
				}
			}
		},
		formatTo(value) {
			if (typeof this.settings.formatTo == "function") {
				value = this.settings.formatTo(value);
			}
			if (typeof this.settings.formatTo == "string") {
				if (this.settings.formatTo == "number") {
					value = Number(value);
				}
			}
			return value;
		},
		onKeypress(event) {
			// prevent adding vlaues that lead to isNaN
			if (this.settings.mask == "number") {
				let curval = event.srcElement.value;
				let newchar = String.fromCharCode(event.charCode || event.keyCode);
				let curval_arr = curval.split("");
				curval_arr.splice(
					event.target.selectionStart,
					event.target.selectionEnd - event.target.selectionStart,
					newchar
				);
				let newval = curval_arr.join("");
				newval = this.maskNumberGetValue(newval);
				if (isNaN(Number(newval))) {
					event.preventDefault();
					return;
				}
			}
		},
		maskValue(value) {
			var cursorPos = value ? value.length : this.$refs.input.selectionEnd;
			let inputValue = value || this.$refs.input.value;

			let masked;
			if (this.settings.mask == "number") {
				inputValue = this.maskNumberGetValue(inputValue);
				this.dynamicMask = this.maskNumberGetMasked(inputValue);
				masked = this.maskGetMask(inputValue, cursorPos);
				masked.unmasked = Number(this.maskNumberGetValue(masked.unmasked));
			} else {
				masked = this.maskGetMask(inputValue, cursorPos);
			}

			this.$refs.input.value = masked.value;
			this.$refs.input.setSelectionRange(masked.cursorPosMasked, masked.cursorPosMasked);

			return {
				unmasked: masked.unmasked,
				masked: masked.value,
			};
		},
		maskNumberGetMasked(value) {
			let masked = Number(value).toLocaleString("de-ch", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			masked = masked.replaceAll(/\d/gi, "#");
			return masked;
		},
		// filter out all chars except numbers and decimalSeparator
		maskNumberGetValue(masked) {
			if (!masked) return null;
			let rgx = new RegExp(`[^\\d${this.separatorDecimal}]`, "gi");
			return masked.replaceAll(rgx, "");
		},
		maskGetMask(value, valueCursorPos) {
			value = value || "";
			let maskIndex = 0;
			let valueIndex = 0;
			let cursorPosMasked = 0;
			let cursorPosRaw = 0;
			let output = "";
			let outputUnmasked = "";

			function addCursorPos(valueCursorPos, valueIndex, valueLength) {
				// cursor ist at the end of value
				if (valueCursorPos == valueLength) {
					return valueCursorPos && valueCursorPos >= valueIndex ? true : false;
				}
				// cursor is somewhere between
				return valueCursorPos && valueCursorPos > valueIndex ? true : false;
			}

			while (valueIndex < value.length && maskIndex < this.dynamicMask.length) {
				let maskToken = this.dynamicMask[maskIndex]; // token (not a placeholder)
				let placeholder = this.tokens[maskToken];
				let testChar = value[valueIndex];

				// mask placeholder with pattern
				if (placeholder) {
					if (placeholder.escape) {
						maskIndex++; // take the next mask char and treat it as char
						maskToken = this.dynamicMask[maskIndex];
						continue;
					}
					if (placeholder.pattern.test(testChar)) {
						output += placeholder.transform ? placeholder.transform(testChar) : testChar;
						outputUnmasked += testChar;
						maskIndex++;
						if (addCursorPos(valueCursorPos, valueIndex)) {
							cursorPosMasked++;
							cursorPosRaw++;
						}
					}
					valueIndex++; // inspect next value
					continue;
				}

				// mask maskToken (not a placeholder with a pattern)
				if (testChar === maskToken) {
					output += maskToken; // add to output
					// exception for mask == 'number: we need decimal separator
					if (this.settings.mask == "number" && maskToken == this.separatorDecimal) {
						outputUnmasked += maskToken;
					}
					valueIndex++;
					if (addCursorPos(valueCursorPos, valueIndex, value.length)) {
						cursorPosMasked++;
					}
					//cursorPosMasked++;
					maskIndex++;
				} else {
					output += maskToken; // add to output
					// exception for mask == 'number: we need decimal separator
					if (this.settings.mask == "number" && maskToken == this.separatorDecimal) {
						outputUnmasked += maskToken;
					}
					cursorPosMasked++;
					maskIndex++;
				}
			}

			// fix mask that ends with a char: (#)
			let restOutput = "";
			while (value && maskIndex < this.dynamicMask.length && this.settings.maskShow) {
				let cMask = this.dynamicMask[maskIndex];
				restOutput += cMask;
				maskIndex++;
			}

			return {
				value: output + restOutput,
				unmasked: outputUnmasked,
				cursorPosMasked,
				cursorPosRaw,
			};
		},
		setAutoWidthText(value) {
			this.$el.dataset.value = value;
		},
		setLabelText(value) {
			this.$el.dataset.lbl = value;
		},
		onClear() {
			this.onInput(null);
			this.$emit("clear");
		},
	},
	computed: {
		settings() {
			let readonly = this.readonly === false ? false : true;
			return {
				label: this.label,
				readonly: readonly,
				disabled: this.disabled,
				notification: this.notification,
				placeholder: this.placeholder,
				notificationIsError: this.notificationIsError,
				type: this.type,
				spellcheck: this.spellcheck,
				autocomplete: this.autocomplete,
				inputmode: this.inputmode,
				selectOnFocus: true,
				// mask:
				mask: this.mask,
				maskShow: this.maskShow,
				maskReturnValue: "unmasked", // umasked || masked
				before: null, // text inserted before input
				after: null, // text inserted after inptut
				formatTo: null, // string or function to format value before emit
				...this.options,
			};
		},
		placeholderText() {
			if (this.settings.placeholder) return this.settings.placeholder;
			if (this.settings.maskShow) return this.settings.mask;
			return null;
		},

		inputName() {
			if (this.settings.name) return this.settings.name;
			if (this.settings.autocomplete != "new-password") return this.settings.label;
			return this.inputId;
		},
		inputId() {
			return this.options.id ? this.options.id : this.uID;
		},
		inputType() {
			if (this.settings.type == "password" && this.pwMask == false) {
				return "text";
			}
			return this.settings.type;
		},
		inputmodeValue() {
			if (this.settings.mask == "number") return "numeric";
			return this.settings.inputmode;
		},
	},
	watch: {
		value: {
			immediate: false,
			handler: function (value) {
				if (this.componentValue == value) return;
				this.onInput(value, false);
			},
		},
		settings: {
			deep: true,
			handler: function (val) {
				this.setLabelText(val.label);
			},
		},
	},
	created() {
		let formatter = new Intl.NumberFormat("de-CH", {});
		let parts = formatter.formatToParts(1000.1);
		this.separatorDecimal = parts[3].value;
		this.dynamicMask = this.settings.mask;
		this.componentValue = null;
	},
	mounted() {
		this.setLabelText(this.settings.label);
		this.onInput(this.value, false);
	},
};
</script>

<style scoped>
	/* click events are eaten by disabled inputs this enables to catch a click event on parent element*/
	input[disabled] {
		pointer-events: none;
	}
	input {
		width: inherit;
	}
	.Input {
		display: inline-flex;
		flex-direction: column;
		position: relative;
		align-items: flex-start;
		text-align: left;
	}
	.Input::before {
		content: attr(data-lbl) " ";
		font-size: 12px;
		max-width: 100%;
		top: 6px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;
		color: rgba(0, 0, 0, 0.54);
	}

	.Input::after {
		width: auto;
		min-width: 1em;
		grid-area: 1 / 2;
		font: inherit;
		padding: 0 0.25em;
		margin: 0;
		resize: none;
		background: none;
		appearance: none;
		border: none;
		content: attr(data-value) " ";
		visibility: hidden;
		white-space: nowrap;
		height: 1px;
	}
	.Input.basic::before,
	.Input.basic::after {
		display: none;
	}

	.Input.basic .wrapper::before,
	.Input.basic .wrapper::after,
	.Input.basic .notification {
		display: none;
	}

	.Input.noLabel::before {
		display: none;
	}
	.Input.noLabel .wrapper input {
		padding-top: 0;
	}
	.Input.basic .wrapper input {
		padding: 0;
	}
	.wrapper {
		display: flex;
		max-width: 100%;
		min-width: 50px;
		width: 100%;
		position: relative;
	}
	.wrapper > .before,
	.wrapper > .after {
		align-self: center;
	}
	.beforeText {
		display: flex;
		align-self: center;
		margin-right: 5px;
	}
	.afterText {
		display: flex;
		align-self: center;
		margin-left: 5px;
	}
	.wrapper::before {
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
		width: 100%;
		border-style: solid;
		border-width: 1px 0 0 0;
		border-color: #619cca;
		z-index: 1;
		white-space: nowrap;
	}
	.Input.readonly:not(.datePickerEnabled) .wrapper::before {
		border-color: rgba(0, 0, 0, 0.42);
	}
	.wrapper::after {
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
		width: 100%;
		transform: scaleX(0);
		border-style: solid;
		border-width: 1px 0 1px 0;
		border-color: #619cca;
	}
	.Input.readonly:not(.datePickerEnabled) .wrapper::after {
		border-color: rgba(0, 0, 0, 0.42);
	}
	.Input.focus:not(.readonly) .wrapper::after {
		transform: scaleX(1);
	}
	.Input.datePickerEnabled.focus .wrapper::after {
		transform: scaleX(1);
	}

	input {
		outline: none;
		border: 0;
		flex: 1 1 auto;
		line-height: 20px;
		padding: 8px 0 7px;
		max-width: 100%;
		position: relative;
		white-space: nowrap;
		text-overflow: ellipsis;
		background-color: transparent;
	}

	input:disabled {
		background-color: inherit;
		border: 0;
	}

	.notification {
		font-size: 12px;
		line-height: 12px;
		margin-top: 4px;
		height: 12px;
	}
	.notification.error {
		color: red;
	}

	.pwMask {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-size: 16px;
	}
	.pwMask:hover {
		transform: scale(1.3);
	}

	.Input.textAlignRight input {
		text-align: right;
	}

	/* selection color */
	input::-moz-selection {
		/* Firefox */
		background: rgb(218, 218, 218);
	}
	.Input:not(.readonly) input::-moz-selection {
		/* Firefox */
		background: #cbe2f7;
	}

	input::selection {
		background: rgb(218, 218, 218);
	}
	.Input:not(.readonly) input::selection {
		background: #cbe2f7;
	}
	.clearIcon {
		font-size: 11px;
		margin: 0 3px;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 12px;
	}
</style>
