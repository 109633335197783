<template>
	<div class="Icon icon" :class="iconClass" @click="$emit('click', $event)">
		<svg xmlns="http://www.w3.org/2000/svg" v-if="icon" :viewBox="iconData.viewBox" width="48" height="48">
			<template v-for="(svgDataEl, index) in iconData.svgData">
				<path v-if="svgDataElType(svgDataEl) == 'path'" v-bind="svgDataEl.path" :key="index" />
			</template>
		</svg>
		<slot v-else-if="$scopedSlots.default"></slot>
		<div v-else>n/a</div>
	</div>
</template>


<script>
export default {
	name: "Icon",
	props: {
		/**
		 * Icon Object or Icon String
		 */
		icon: {
			required: false,
			type: [Object, String],
		},
		spin: {
			required: false,
			default: false,
		},
	},
	methods: {
		svgDataElType(svgDataEl) {
			return Object.keys(svgDataEl)[0];
		},
	},
	computed: {
		/**
		 * get Icon Data from Object or $icons
		 */
		iconData: function () {
			if (typeof this.icon == "object") return this.icon;
			if (typeof this.icon == "string") {
				return this.$icons[this.icon] ? this.$icons[this.icon] : {};
			}
			return {};
		},
		/**
		 *  returns vondor class
		 */
		iconClass: function () {
			if (this.icon && this.icon.vendor) {
				return {
					[this.icon.vendor]: true,
					spin: this.spin === false ? false : true,
				};
			}
			return {
				spin: this.spin === false ? false : true,
			};
		},
	},
};
</script>

<style scoped>
	.Icon {
		display: flex;
		align-items: center;
	}

	.Icon svg {
		height: 1em;
		width: 1em;
		fill: currentColor;
		position: relative;
	}
	.Icon.UiFabric svg {
		height: 0.9333333333333333em;
		width: 0.9333333333333333em;
	}
	.Icon.spin svg {
		animation: spin 2s linear infinite;
	}
	.Icon.UiFabric.sizeM svg {
		height: 18px;
		width: 18px;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>