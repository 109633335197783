<template>
	<div id="portalToolbar">
		<div id="toolbarNav">
			<slot v-if="show.toolbarNav" name="toolbarNav"></slot>
		</div>
		<div id="toolbarTitle">
			<span class="companyName">{{company}}</span>
			<span class="portalName">{{title}}</span>
		</div>
		<div id="toolbarCenter">
			<slot v-if="show.toolbarCenter" name="toolbarCenter"></slot>
		</div>
		<div id="toolbarButtons">
			<slot v-if="show.toolbarButtons" name="toolbarButtons"></slot>
			<div
				v-if="show.toolbarButtons"
				:class="'button ' + buttonActive(drawers.help)"
				@click="$parent.$emit('drawer', 'toggle.help')"
			>
				<i class="ms-Icon ms-Icon--Help" />
			</div>
			<div
				class="button user"
				:class="{active: actionUserShow}"
				v-if="buttonUserShow"
				@click="onUserButtonClick"
			>
				<div class="iconUser">
					<Icon :icon="buttonUser.icon" />
				</div>
				<UserSettingsAction
					ref="UserSettingsAction"
					v-if="actionUserShow"
					:user="userConfig"
					:client="clientConfig"
					@close="buttonUser.showSettings = false"
				/>
			</div>
			<div
				v-if="show.toolbarButtons"
				:class="'button ' + buttonActive(drawers.settings)"
				@click="$parent.$emit('drawer', 'toggle.settings')"
			>
				<i class="ms-Icon ms-Icon--Settings" />
			</div>
		</div>
		<div v-if="progressShow" class="loader"></div>
	</div>
</template>

<script type="text/javascript">
import { Contact } from "@icons/appFabric/icons";
import icon from "@components/Tools/Icon";
import eventBus from "../eventBus";
import UserSettings from "./UserSettings";
let listeners = [];
export default {
	components: {
		Icon: icon,
		UserSettingsAction: UserSettings,
	},
	props: {
		drawers: {
			type: Object,
			required: false,
			default: function () {
				return {
					user: false,
					settings: false,
					help: false,
				};
			},
		},
		userConfig: {
			type: Object,
			required: true,
		},
		clientConfig: {
			type: Object,
			required: true,
		},
		show: {
			type: Object,
			required: false,
			default: function () {
				return {
					toolbarNav: true,
					toolbarTitle: true,
					toolbarCenter: true,
					toolbarButtons: false,
				};
			},
		},
	},
	data: function () {
		return {
			progressStack: [],
			buttonUser: {
				icon: Contact,
				showSettings: false,
			},
		};
	},
	methods: {
		onUserButtonClick() {
			if (this.$parent.authenticated !== true) return;
			this.buttonUser.showSettings = !this.buttonUser.showSettings;
		},
		buttonActive: function (drawerType) {
			if (drawerType) return "active";
			return "";
		},
		progressStart: function (id) {
			this.progressStack.push(id);
		},
		progressStop: function (id) {
			if (id) {
				//remove this one id
				let index = this.progressStack.indexOf(id);
				if (index > -1) {
					this.progressStack.splice(index, 1);
				}
			} else {
				//remove all
				this.$set(this.progressStack, []);
			}
		},
	},
	computed: {
		company: function () {
			return this.$globalStatics.portalConfig.companyName;
		},
		title: function () {
			return this.$globalStatics.portalConfig.title;
		},
		buttonUserShow: function () {
			return this.userConfig.userId ? true : false;
		},
		actionUserShow: function () {
			return this.buttonUserShow && this.buttonUser.showSettings;
		},
		progressShow: function () {
			return this.progressStack.length > 0;
		},
	},
	created() {
		listeners.push(eventBus.on("progressBar.start", this.progressStart));
		listeners.push(eventBus.on("progressBar.stop", this.progressStop));
		listeners.push(
			eventBus.on("auth.logoff", () => {
				this.buttonUser.showSettings = false;
			})
		);
	},
	beforeDestroy() {
		listeners.forEach((event) => {
			event.off();
		});
	},
};
</script>

<style scoped>
	#portalToolbar {
		display: flex;
		width: 100%;
		position: relative;
		height: 64px;
		background-color: #001e41;
		color: white;
	}
	#toolbarTitle {
		font-family: "Roboto", sans-serif;
		font-size: 28px;
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: 10px;
		margin-right: 20px;
	}

	#toolbarCenter {
		flex-grow: 1;
		display: flex;
		align-items: center;
	}
	#toolbarButtons {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#toolbarNav {
		width: 64px;
	}

	#toolbarButtons .button:not(.active):hover {
		background-color: var(--apleona-blau1);
	}
	#toolbarTitle > .companyName {
		font-family: "Radikal", sans-serif;
		text-transform: uppercase;
		color: #e43839;
	}
	#toolbarTitle > .portalName {
		font-weight: 300;
		margin-left: 13px;
	}
	.button {
		height: 100%;
		width: 64px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid transparent;
	}
	.button.active {
		background-color: var(--menuBgActive);
		color: var(--apleona-blau);
	}
	.button .ms-Icon {
		font-size: 16px;
	}
	.iconUser {
		height: 32px;
		width: 32px;
		border-radius: 50%;
		border: 1px solid #e2e1e1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.button.active .iconUser {
		border-color: var(--apleona-blau);
	}
	.iconUser .icon {
		font-size: 20px;
		transform: translateY(-2px);
	}
	.loader {
		height: 4px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		overflow: hidden;
		background-color: transparent;
	}
	.loader:before {
		display: block;
		position: absolute;
		content: "";
		left: -200px;
		width: 200px;
		height: 4px;
		background-color: #ce3939;
		animation: loading 2s linear infinite;
	}

	@keyframes loading {
		from {
			left: -200px;
			width: 30%;
		}
		50% {
			width: 30%;
		}
		70% {
			width: 30%;
		}
		80% {
			left: 50%;
		}
		95% {
			left: 120%;
		}
		to {
			left: 100%;
		}
	}
</style>
