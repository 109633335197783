


export function addRoutesMetaData(routes, metaObject) {
	return routes.map(route => {
		//add to route
		route = addMetaData(route, metaObject)
		//add to children
		if (route.children) {
			route.children = addChildrenMetaData(route.children, metaObject)
		}
		return route
	});

}

function addChildrenMetaData(children, metaObject) {
	return children.map(route => {
		route = addMetaData(route, metaObject)
		//add to children
		if (route.children) {
			route.children = addChildrenMetaData(route.children, metaObject)
		}
		return route
	})
}

function addMetaData(route, metaObject) {
	//add to route
	if (!route.meta) route.meta = {}
	for (const key in metaObject) {
		route.meta[key] = metaObject[key]
	}
	return route
}

var timers = {}

export function delay(name, cb, delay) {
	//cancel existing timer
	if (timers[name]) clearTimeout(timers[name])
	//start new timer
	timers[name] = setTimeout(() => {
		cb();
		delete timers[name]
	}, delay)
}

export function delayClear(name) {
	//cancel existing timer
	if (timers[name]) clearTimeout(timers[name])
}


const doubleRequestAnimationFrame = (callback) => {
	requestAnimationFrame(() => {
		requestAnimationFrame(callback)
	})
}
//how to use:
//https://github.com/twickstrom/vue-force-next-tick
export function displayUpdate(callback) {
	if (callback && typeof callback === 'function') {
		doubleRequestAnimationFrame(callback)
	} else {
		return new Promise(resolve => {
			doubleRequestAnimationFrame(resolve)
		})
	}
}

export function getLang(locale) {
	if (typeof locale != 'string') return
	if (locale.length == 2) return locale
	return locale.split('-')[0]
}


