<template>
	<div v-if="menuShow" class="Menu" :class="menuClass" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
		<div ref="toggle" class="menuToggle">
			<div class="menuItem" @click="onMenuToggleClick">
				<div class="grow"></div>
				<div class="iconSlot">
					<Icon :icon="iconExpand" />
				</div>
			</div>
		</div>

		<template v-for="(item, index) in menuItems">
			<div class="app" :key="index" :class="{ currentApp: item.active }">
				<template v-for="(menu, i) in item.menus">
					<MenuItem
						:key="i"
						:menu-item="menu"
						:level="0"
						@toggleMenuChildren="onToggleMenuChildren"
						@wrapperLeave="onWrapperleave"
						@wrapperEnter="onWrapperEnter"
					/>
				</template>
			</div>
		</template>
	</div>
</template>

<script>
import { ChevronRight } from "@icons/appFabric/icons";
import MenuItem from "./MenuItem";
import Icon from "../Tools/Icon";
import { delay, delayClear } from "./portalHelpers";
import eventBus from "./eventBus";

export default {
	name: "Menu",
	components: {
		MenuItem,
		Icon,
	},
	props: {
		menuState: {
			type: Object,
			required: true,
		},
		appMenus: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			opening: false,
			closing: false,
			iconExpand: ChevronRight,
			mouseIsOver: false,
			expanded: false,
			fixed: false,
		};
	},
	methods: {
		onMouseEnter(e) {
			this.mouseIsOver = true;
			setTimeout(() => {
				if (this.mouseIsOver) {
					this.expanded = true;
				} else {
					this.expanded = false;
				}
			}, 700);
		},
		onMouseLeave() {
			this.mouseIsOver = false;
			setTimeout(() => {
				if (!this.mouseIsOver) {
					this.expanded = false;
				}
			}, 500);
		},
		onMenuToggleClick() {
			if (this.menuState.expanded) {
				this.closing = true;
				this.menuState.expanded = false;
				this.expanded = false;
			} else {
				this.opening = true;
				this.menuState.expanded = true;
			}
			eventBus.emit("menu.toggle", {
				expanded: this.menuState.expanded,
			});
			this.$put(`user/menuExpanded/` + this.menuState.expanded.toString());
		},
		onToggleMenuChildren: function (menuItem) {
			//get children.show
			let show = menuItem.items[0].show;
			//toggle all children
			this.toggleMenuChildren(menuItem, !show);
		},
		//get reference to menuItem descripbed in path
		getMenuItemRef: function (path) {
			path = path.split(".");
			//
			let elRef = this.menuData;
			for (let i = 0; i < path.length; i++) {
				if (i == 0) {
					let menuDataIndex = elRef.findIndex((item) => {
						return item._id == path[i];
					});
					elRef = elRef[menuDataIndex];
				} else {
					let menuDataIndex = elRef.items.findIndex((item) => {
						return item._id == path[i];
					});
					elRef = elRef.items[menuDataIndex];
				}
			}
			return elRef;
		},
		//set all menuItem.items[].show to value recursive
		toggleMenuChildren: function (menuItem, value) {
			if (!menuItem.items) return;
			menuItem.items.forEach((item, index) => {
				menuItem.items[index].show = value;
				//if setting false => set all children recursive
				if (menuItem.items[index].items && value == false) {
					this.toggleMenuChildren(menuItem.items[index], value);
				}
			});
		},
		transitionEnd: function (event) {
			if (event.target == this.$el) {
				this.opening = false;
				this.closing = false;
			}
		},
		menuItemClass: function (menudata) {
			if (menudata.active) return " active " + menudata.class;
			return " " + menudata.class;
		},
		onWrapperleave(menuItem, level) {
			//if root wrapper => collapse tree

			if (level == 0 && !this.menuState.expanded) {
				//delayed toggleMenuChildren
				delay(
					`wrapper${level}`,
					() => {
						this.toggleMenuChildren(menuItem, false);
					},
					500
				);
			}
		},
		onWrapperEnter(menuItem, level) {
			if (level == 0 && !this.menuState.expanded) {
				//clear possible delayed toggleMenuChildren
				delayClear(`wrapper${level}`);
			}
		},
	},
	computed: {
		menuItems: function () {
			return this.appMenus;
			// return this.menuData.filter((menuItem) => {
			// 	console.log(menuItem);
			// 	//if appName is set on menuItem -> check appSettings[appName] if it should be displayed
			// 	// if (menuItem.appName) {
			// 	// 	return this.appSettings[menuItem.appName] ? true : false;
			// 	// }
			// 	//default is to return menuItem
			// 	return true;
			// });
		},
		menuClass: function () {
			return {
				opening: this.opening,
				closing: this.closing,
				menuExpanded: this.menuState.expanded || this.expanded,
				collapsed: !this.menuState.expanded,
				fixed: this.menuState.expanded,
			};
		},
		menuShow: function () {
			return this.appMenus.length > 0 ? true : false;
		},
	},
	mounted: function (params) {
		this.$el.addEventListener("transitionend", this.transitionEnd);
	},
	updated() {
		this.$el.removeEventListener("transitionend", this.transitionEnd);
		this.$el.addEventListener("transitionend", this.transitionEnd);
	},
	beforeDestroy() {
		this.$el.removeEventListener("transitionend", this.transitionEnd);
	},

	watch: {
		menuState: {
			deep: true,
			handler(to) {
				//collapse iv menu is retracted
				if (!to.expanded) {
					this.appMenus.forEach((appMenu) => {
						appMenu.menus.forEach((menu) => {
							this.toggleMenuChildren(menu, false);
						});
					});
				}
			},
		},
	},
};
</script>

<style scoped>
	/* Menu */
	.Menu {
		position: absolute;
		left: 0;
		top: 0;
		min-height: 100%;
		width: 50px;
		background-color: #baccdd;
		z-index: 5;
		display: flex;
		flex-direction: column;

		overflow: hidden;
	}
	#Portal.ready #portalContent.isAnimating .Menu {
		transition: width 0.5s;
		transition-timing-function: ease;
	}
	.Menu.menuExpanded {
		width: 200px;
	}
	.Menu.fixed .menuToggle .icon {
		transform: rotate(-180deg);
	}
	.menuToggle {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 15px;
	}
	.menuItem {
		display: flex;
		justify-content: flex-start;
		height: 50px;
		width: 100%;
		position: relative;
		cursor: pointer;
	}
	.menuItem .grow {
		flex-grow: 0;
		transition: all 0.5s;
	}
	.Menu.fixed .grow {
		flex-grow: 1;
	}

	.menuItem .iconSlot {
		height: 100%;
		width: 50px;
		position: relative;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.menuItem .icon {
		transition: transform 0.5s;
		transition-timing-function: ease;
	}

	.app {
		margin: 0;
		background-color: var(--menuBg);
	}
	.app.currentApp {
		background-color: var(--menuBgActive);
	}
	.app.currentApp >>> .level1 .menuItem:hover {
		background-color: var(--menuBgActive);
	}
	/* menuItems */
	.menuItem:hover {
		background-color: var(--menuBgActive);
	}
	.menuItemsGroup.active:after {
		content: "";
		background-color: var(--accentBlue);
	}
</style>
