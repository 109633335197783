import utils from "@SyoLab/utils"

export function appMenuShow(portal, appName) {
	if (!portal.portalApps[appName]) return false
	//no menu configured => return false
	if (!portal.portalApps[appName].menu) {
		//console.log("appMenuShow", appName, "no.menu")
		return false
	}
	// menuShow set to true => always show
	if (portal.portalApps[appName].menuShow == true) {
		//console.log("appMenuShow", appName, "no.menuShow")
		return true
	}
	//apps with authorization:
	if (portal.portalApps[appName].auth) {
		//if user has not logged in => do not show
		if (portal.authenticated === undefined) {
			//console.log("appMenuShow", appName, "portal.authenticated undefined")
			return false
		}
		//logged in user:
		//check if app is configured for this user
		if (!portal.userApps[appName]) return false
		if (typeof portal.userApps[appName].enabled != "undefined") {
			return portal.userApps[appName].enabled ? true : false
		}
		return true
	} else {
		//console.log("appMenuShow", appName, "no.auth")
		return false
	}

}

/**
 * Prepare config menu items for portal
 */
export function menuImport(menus, appName, userConfig) {

	return menus.map(item => {
		item.appName = appName
		item._id = utils.shortId()
		item.active = false
		if (item.items) {
			item.items = menuImport(item.items, appName)
		}
		return item
	})
}