import utils from "@SyoLab/utils"
import { BarChartVerticalFill } from "../../@icons/appFabric/icons"
const reports = () => import(/* webpackChunkName: "Reports" */"./Reports.vue")
const appName = 'reports'

export default {
    name: appName,
    auth: true, //auth: if authenticated must have app in userApps
    menuShow: false, // always show
    menuPosition: 6, //sorting menu position of app
    component: reports,
    routes: [
        {
            name: "reports",
            path: "/reports"
        }
    ],
    menu: [
        {
            "_id": utils.shortId(),
            "appName": appName,
            "text": "Reports",
            "class": "reports",
            "route": "/reports", //route path
            "action": 'route', //action emitted to app
            "show": true,
            "icon": BarChartVerticalFill
        },
    ]
}