import utils from "@SyoLab/utils"
import { Health } from "../../@icons/appFabric/icons"
const deployApp = () => import(/* webpackChunkName: "Quentic" */"./Quentic.vue")
const appName = 'quentic'
export default {
   name: appName,
   auth: true, //auth: if authenticated must have app in userApps
   menuShow: false, //menuShow overrides everything
   menuPosition: 5, //sorting menu position of app
   component: deployApp,
   routes: [
      {
         path: "/quentic*"
      }
   ],
   menu: [
      {
         "_id": utils.shortId(),
         "appName": appName,
         "text": "Quentic",
         "class": "siteClass",
         "route": "/quentic", //route path
         "action": undefined, //action emitted to app
         "active": true,
         "show": true,
         "icon": Health
      }
   ]
}