
import eventBus from "./eventBus";
import utils from "@SyoLab/utils"



async function getResponseBody(response, fetchOptions) {

	// download
	if (fetchOptions.download) {
		let contentDisposition = response.headers.get('Content-Disposition')
		let filename
		if (!contentDisposition) {
			console.warn('no Content-Disposition header set')
		} else {
			filename = contentDisposition.split('=').pop()
		}
		response.filename = filename
		return await response.blob()
	}
	// blob
	if (fetchOptions.blob) {
		return await response.blob()
	}

	// json
	let contentType = response.headers.get("Content-Type");
	if (contentType && contentType.includes("application/json")) {
		return await response.json()
	} else {
		return null
	}
}

function getRequestBody(data) {

	let ret = {
		body: data || null,
		contentType: null
	}

	if (data && typeof data == "object") {
		// FormData
		if (data instanceof FormData) return ret
		// Json
		ret.body = JSON.stringify(data)
		ret.contentType = 'application/json;charset=utf-8'
		return ret
	}

	if (data && typeof data == "string") {
		ret.contentType = "text/plain"
		return ret
	}

	return ret
}


class FetchError extends Error {
	constructor(response) {
		let message = response.data.message ? response.data.message : response.statusText
		super(message);
		this.name = response.statusText;
		if (response.data) this.data = response.data
	}
}



var Portal;


export default new class {
	accessJWT = {
		token: undefined,
		expires: undefined,
		hasExpired: function () {
			if (!this.token) return true
			return this.expires < new Date()
		}
	}
	authTTL = undefined
	connected = false
	userId = undefined

	constructor() {

		//set events
		eventBus.on("auth.logoff", (noFetch) => {
			this.logoff(noFetch)
		})

		window.removeEventListener('storage', this.onStorageChange)
		window.addEventListener('storage', this.onStorageChange.bind(this));
	}

	setPortalRef(portalRef) {
		Portal = portalRef
	}

	onStorageChange(e) {
		if (e.key == 'userId' && e.newValue != this.userId) {
			eventBus.emit('auth.logoff', true)
		}
	}

	async fetch(path, options = {}) {

		let fetchID = utils.shortId()
		let settings = Portal.$globalStatics.portalConfig.api
		let url = `${settings.protocol}${settings.server}${settings.baseUrl}${path}`;

		//default fetch options
		var fetchOptions = {
			method: "GET", //default is GET
			headers: {},
			credentials: 'include',
			authorize: settings.auth === false ? false : true //authorize before request
		};

		//assign options, stringify data
		Object.keys(options).forEach(key => {
			if (key == "data") {
				// set data and content-type
				let { body, contentType } = getRequestBody(options.data)
				fetchOptions.body = body
				if (contentType) fetchOptions.headers['Content-Type'] = contentType
				return
			} else {
				fetchOptions[key] = options[key]
			}

		})


		//start fetch
		eventBus.emit("progressBar.start", fetchID)
		//authorize
		if (fetchOptions.authorize) {
			if (await this.authorize()) {
				fetchOptions.headers.Authorization = "Bearer " + this.accessJWT.token
			} else {
				throw new FetchError({
					statusText: "AuthorizationError",
					status: -1,
				})
			}
		}

		//console.log('fetchOptions', fetchOptions)
		//fetch
		try {
			//console.log(fetchOptions)
			var response = await fetch(url, fetchOptions);
			response.data = await getResponseBody(response, fetchOptions)
			eventBus.emit("progressBar.stop", fetchID)
		} catch (err) {
			//just network error are catched here:
			eventBus.emit("progressBar.stop", fetchID)
			//notify
			eventBus.emit('notify.error', "Verbindungsfehler", {
				body: "Keine Verbindung mit dem Internet",
				timeout: 10000
			})
			//throw error
			throw new FetchError({
				statusText: "NetworkError",
				status: -1,
				data: { message: err.message }
			})
		}

		//all other errors are catched here
		if (!response.ok) {
			//500:
			if (response.status >= 500) {
				//notify server errors
				eventBus.emit('notify.error', "Serverfehler", { timeout: 3000 })
			}
			//401
			if (response.status == 401) {
				//console.error('unauthorized')
			}
			//throw error
			throw new FetchError(response)
		}
		return response

	}

	async post(path, data, options = {}) {

		return this.fetch(path, {
			method: "POST",
			...options,
			data: data,
		})
	}

	async get(path, options = {}) {
		return await this.fetch(path, {
			method: "GET",
			...options,
		})
	}

	async delete(path, data, options = {}) {

		return await this.fetch(path, {
			method: "DELETE",
			...options,
			data: data,
		})
	}

	async put(path, data = {}, options = {}) {

		return await this.fetch(path, {
			method: "PUT",
			...options,
			data: data
		})
	}

	async patch(path, data = {}, options = {}) {

		return await this.fetch(path, {
			method: "PATCH",
			...options,
			data: data
		})
	}

	async serverBuild() {

		try {
			let response = await fetch('/version.json', { cache: "no-store" })
			if (response.ok) {
				response.data = await getResponseBody(response)
			}
			return response.data

		} catch (error) {
			return {}
		}

	}

	async authorize() {

		//if not accessJWT ist set => do not try to renew
		//if (!this.accessJWT.token) return false
		//if accessJWT has not expired => authorized
		if (this.accessJWT.hasExpired() == false) {
			return true
		}

		// try to renew
		try {
			await this.authRenew()
			return true
		} catch (error) {
			return false
		}

	}

	async authRenew() {

		//try to renew accessJWT through CLID and session
		try {

			var response = await this.fetch("auth/renew", {
				method: "GET",
				authorize: false
			})

		} catch (error) {
			this.connected = true
			eventBus.emit("auth.authenticated", false);
			throw new Error("unauthorized");
		}
		//console.log(response.data)
		this.connected = true
		let loginResponse = response.data
		this.onLoginSuccess(loginResponse)
		eventBus.emit("auth.authenticated", true);

	}

	async login(userId, password) {

		this.userId = userId
		localStorage.setItem('userId', userId);

		var response = await this.fetch("auth/login", {
			method: "POST",
			data: {
				userId: userId,
				password: password
			},
			authorize: false
		})

		//if no error => do on LoginSuccess
		let loginResponse = response.data
		this.onLoginSuccess(loginResponse)
		this.setAccessToken(loginResponse.accessJWT)
		//eventBus.$emit("authenticated", true);
		return response.data
	}

	onLoginSuccess(response) {

		this.setAccessToken(response.accessJWT)

		//set authorization timeout
		let ttl = response.clientConfig.ttl || 120
		this.setAuthTTL(ttl)

		// if stayLoggedIn is not activated: setTimeout
		console.log('onLoginSuccess', 'stayLoggeIn', response.clientConfig.stayLoggedIn, ttl)
		if (!response.clientConfig.stayLoggedIn) {

			let delay = ttl * 60 * 1000 - 10000
			setTimeout(() => {
				if (Portal && Portal.clientConfig && Portal.clientConfig.stayLoggedIn) return
				let now = new Date()
				//check if auth ttl is still the set value
				if (!this.authTTL || this.authTTL < now) {
					eventBus.emit("auth.authenticated", false)
				}
			}, delay) //add margin
		}


		eventBus.emit("userApps.set", response.userApps);
		eventBus.emit("userConfig.set", response.userConfig);
		eventBus.emit("clientConfig.set", response.clientConfig);

	}

	async setAccessToken(jwtResponse) {
		this.accessJWT.token = jwtResponse.token
		this.accessJWT.expires = getJWTExpiration(jwtResponse)
	}

	setAuthTTL(ttl) {

		let exp = new Date();
		let minutes = exp.getMinutes() + ttl
		let seconds = exp.getSeconds() - 30 // subtract 30' tolerance
		exp.setMinutes(minutes, seconds);
		this.authTTL = exp
	}

	async logoff(noFetch) {
		this.accessJWT.token = undefined
		this.accessJWT.expires = undefined
		localStorage.setItem('userId', null);
		if (noFetch) return
		await this.fetch("auth/logoff", {
			method: "GET",
			authorize: false
		})
	}
}

function getJWTExpiration(jwtResponse) {
	//set expiration with client time
	//this mitigates server/client time differences
	let ttl = Number(jwtResponse.ttl)
	let exp = new Date();
	let minutes = exp.getMinutes() + ttl
	let seconds = exp.getSeconds() - 30 // subtract 30' tolerance
	exp.setMinutes(minutes, seconds);
	return exp
}