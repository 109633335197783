<template>
	<div :class="{ notification: true, info: notification.info }">
		<div class="msgRow">
			<div v-if="notification.type == 'info'" class="icon bg info">
				<svg width="100%" height="100%" viewBox="0 0 512 512">
					<path
						d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
					/>
				</svg>
			</div>

			<div v-if="notification.type == 'error'" class="icon bg error">
				<svg width="100%" height="100%" viewBox="0 0 512 512">
					<path
						d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
					/>
				</svg>
			</div>

			<div v-if="notification.type == 'warning'" class="icon bgtriangle warning">
				<svg width="100%" height="100%" viewBox="0 0 576 512">
					<path
						d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
					/>
				</svg>
			</div>

			<div class="msg">{{ notification.msg }}</div>
			<div v-if="notification.body" :class="{ icon: true, more: true, show: expand }" @click="expand = !expand">
				<svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 12 7.409999" width="100%">
					<path d="M 1.41,0 6,4.58 10.59,0 12,1.41 l -6,6 -6,-6 z" id="path2" inkscape:connector-curvature="0" />
					<path d="m -6,-8.59 h 24 v 24 H -6 Z" id="path4" inkscape:connector-curvature="0" style="fill: none" />
				</svg>
			</div>
			<div class="action confirm" @click="$emit('dismiss', notification.id)">
				<svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 24" width="100%">
					<path
						class="cross"
						d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
					/>
					<path d="M0 0h24v24H0z" fill="none" />
				</svg>
			</div>
		</div>
		<SlideDown v-if="notification.body" :show="expand">
			<div v-if="bodyIsVue">
				<component :is="notification.body" />
			</div>
			<div v-else class="body" v-html="notification.body"></div>
		</SlideDown>
	</div>
</template>

<script type="text/javascript">
import SlideDown from "../Containers/SlideDown";
export default {
	name: "Notification",
	components: {
		SlideDown,
	},
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
	data: function () {
		return {
			expand: false,
		};
	},
	computed: {
		bodyIsVue: function () {
			if (this.notification.body && typeof this.notification.body == "object") {
				return this.notification.body.render && typeof this.notification.body.render == "function";
			}
			return false;
		},
	},
	created() {
		if (this.notification.expand) this.expand = this.notification.expand;
	},
};
</script>

<style scoped>
	.notification {
		width: 300px;
		background-color: #4c668b;
		color: white;
		padding: 10px;
	}

	.msgRow {
		display: flex;
		align-items: center;
		font-weight: 300;
	}
	.msg {
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-right: 5px;
	}
	.body {
		padding-top: 5px;
		font-weight: 300;
	}
	.icon {
		margin-right: 8px;
		position: relative;
		z-index: 0;
		min-width: 20px;
		max-width: 20px;
		height: 20px;
	}
	.icon.bg::before {
		content: " ";
		position: absolute;
		width: 80%;
		height: 80%;
		border-radius: 50%;
		background-color: white;
		z-index: -1;
		transform: translate(10%, 10%);
	}
	.icon.bgtriangle::before {
		content: " ";
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 9px 16px 10px;
		border-color: transparent transparent white transparent;
		z-index: -1;
		top: 2px;
	}
	.icon.error svg path {
		fill: #e02829;
	}
	.icon.info {
		height: 22px;
		width: 22px;
	}
	.icon.info path {
		fill: rgb(4, 118, 249);
	}
	.icon.warning path {
		fill: orange;
	}

	.action {
		display: flex;
		cursor: pointer;
	}
	.action.confirm {
		height: 20px;
		width: 20px;
	}
	.action.confirm svg path.cross {
		fill: white;
	}
	.icon.more {
		max-width: 11px;
		min-width: 11px;
		cursor: pointer;
		transition-duration: 0.3s;
	}
	.icon.more.show {
		transform: rotate(180deg);
	}
	.icon.more svg path {
		fill: white;
	}
</style>
