<template>
	<Popover
		:anchorEl="'#Portal'"
		:refEl="'.button.user'"
		orientation="bottom"
		align="right"
		@outsideClick="onOutsideclick"
	>
		<div class="toolbarAction boxShadow triangle-top">
			<div class="popup">
				<h6>{{ $t("myAccount") }}</h6>
				<p class="bold">{{ user.firstName }} {{ user.lastName }}</p>
				<p>{{ user.userId }}</p>
				<div class="row settings">
					<span>{{ $t("stayLoggedIn") }}</span>
					<ToggleButton
						:labels="{ checked: $t('yes'), unchecked: $t('no') }"
						:width="50"
						v-model="client.stayLoggedIn"
						@change="onStayLoggedInChange"
					/>
				</div>
				<div class="row lang">
					<span>{{ $t("languages") }}:</span>
					<div class="langList">
						<span
							v-for="(lang, index) in $globalStatics.portalConfig.languages"
							:key="index"
							:class="{ active: activeLang(lang) }"
							@click="setLang(lang)"
							>{{ getLang(lang) }}</span
						>
					</div>
				</div>
				<div class="row">
					<Button @click="onLogoff">{{ $t("logOff") }}</Button>
				</div>
			</div>
		</div>
	</Popover>
</template>

<i18n>
{
	"de": {
		"languages": "Sprachen",
		"logOff": "Abmelden",
		"myAccount": "Mein Konto",
		"no": "Nein",
		"stayLoggedIn": "Angemeldet bleiben",
		"yes": "Ja"
	},
	"fr": {
		"languages": "Langues",
		"logOff": "Déconnexion",
		"myAccount": "Mon compte",
		"no": "non",
		"stayLoggedIn": "Rester connecté",
		"yes": "oui"
	},
	"it": {
		"languages": "Lingue",
		"logOff": "Logout",
		"myAccount": "Il mio account",
		"no": "No",
		"stayLoggedIn": "Rimani collegato",
		"yes": "Si"
	}
}
</i18n>

<script>
import Popover from "@components/Containers/Popover";
import ToggleButton from "@components/Form/Switcher";
import Button from "@components/Form/Button";
import eventBus from "../../Portal/eventBus";
export default {
	components: {
		Popover,
		ToggleButton,
		Button,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
		client: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			preventClose: false,
		};
	},
	methods: {
		onStayLoggedInChange: function (value) {
			this.$put(`user/stayLoggedIn/` + value.value.toString());
		},
		onLogoff: function () {
			eventBus.emit("auth.logoff");
		},
		onOutsideclick(e) {
			if (this.preventClose) return;
			this.$emit("close");
		},
		async setLang(lang) {
			//display loader
			this.preventClose = true;
			eventBus.emit("portal.loader", true);
			await this.$displayUpdate();
			//change locale
			this.$portal.setLocale(lang);
			this.$portal.userConfig.locale = lang;
			await this.$displayUpdate();
			//hide loader
			this.preventClose = false;
			eventBus.emit("portal.loader", false);
			//save choice
			this.$put("user/lang/" + lang);
		},
		getLang(lang) {
			return lang.toUpperCase();
		},
		activeLang(lang) {
			return this.$i18n.locale.split("-")[0] == lang;
		},
	},
	beforeDestroy() {
		if (this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
	},
};
</script>

<style scoped>
	.Popover {
		margin-right: 5px;
	}
	.toolbarAction {
		position: relative;
	}
	.popup {
		position: relative;
		margin-top: 16px;
		background-color: #fff;
		min-width: 230px;
		padding: 15px 15px 25px 15px;
		border: none;
		z-index: 1000;
		border-radius: 0;
		text-align: left;
	}
	.popover {
		padding-right: 10px;
	}
	.bold {
		font-weight: 500;
	}
	.row {
		margin-top: 15px;
		display: flex;
		align-items: center;
	}
	.row.settings {
		justify-content: space-between;
		margin-bottom: 20px;
	}
	.button {
		height: 28px !important;
	}
	.triangle-top::before {
		left: unset;
		right: 10px;
	}
	.row.lang {
		justify-content: space-between;
	}
	.langList {
		margin-left: 5px;
	}
	.langList span {
		padding: 0px 5px;
		background-color: #bfcbd9;
		margin-left: 5px;
		color: white;
		font-size: 13px;
		cursor: pointer;
	}
	.langList span.active {
		background-color: #4c668b;
		color: inherit;
	}
</style>