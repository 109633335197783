<template>
	<div class="SlideDown" :class="{ open: isOpen }">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "SlideDown",
	props: {
		show: {
			type: Boolean,
			required: false,
			default: false,
		},
		duration: {
			type: Number,
			required: false,
			default: 500,
		},
	},
	data: function () {
		return {
			isOpen: true,
		};
	},
	computed: {
		config: function () {
			return {
				duration: this.duration,
			};
		},
	},
	mounted() {
		//if property show is set: set initial display
		if (this.show == false) {
			this.$el.style.display = "none";
			this.isOpen = false;
		}
		//add event listener
		setTimeout(() => {
			this.$root.$on("documentTouchClick", (e) => {
				if (this.show && this.isOpen) {
					if (this.$el && this.$el.contains(e.target)) {
						return;
					}
					this.$emit("outsideClick", e);
				}
			});
		}, 1);
	},
	beforeDestroy() {
		this.$root.$off();
	},
	watch: {
		show: function (newVal) {
			if (newVal === true && this.isOpen == false) this.slideDown(this.config.duration);
			if (newVal === false && this.isOpen == true) this.slideUp(this.config.duration);
		},
	},
	methods: {
		slideUp: function (duration = 500) {
			if (duration == null) duration = this.config.duration;
			this.$emit("startSlideUp", duration);
			let target = this.$el;
			if (duration == 0) {
				target.style.display = "none";
				this.$emit("endSlideUp");
				return;
			}
			// define start point
			let height = target.offsetHeight;
			target.style.height = height + "px";

			// start animation to 0
			setTimeout(() => {
				target.style.transitionProperty = "height, margin, padding";
				target.style.transitionDuration = duration + "ms";
				target.style.boxSizing = "border-box";
				target.style.overflow = "hidden";
				target.style.height = 0;
				target.style.paddingTop = 0;
				target.style.paddingBottom = 0;
				target.style.marginTop = 0;
				target.style.marginBottom = 0;
				window.setTimeout(() => {
					target.style.display = "none";
					target.style.removeProperty("height");
					target.style.removeProperty("padding-top");
					target.style.removeProperty("padding-bottom");
					target.style.removeProperty("margin-top");
					target.style.removeProperty("margin-bottom");
					target.style.removeProperty("overflow");
					target.style.removeProperty("transition-duration");
					target.style.removeProperty("transition-property");
					this.isOpen = false;
					this.$emit("endSlideUp");
				}, duration);
			}, 2);
		},
		slideDown: function (duration = 500) {
			if (duration == null) duration = this.config.duration;
			this.$emit("startSlideDown", duration);
			let target = this.$el;
			target.style.removeProperty("display");
			let display = window.getComputedStyle(target).display;

			if (display === "none") display = "block";
			if (duration == 0) {
				this.$emit("endSlideDown");
				this.isOpen = true;
				return;
			}

			target.style.display = display;
			let height = target.offsetHeight;
			target.style.overflow = "hidden";
			target.style.height = 0;
			target.style.paddingTop = 0;
			target.style.paddingBottom = 0;
			target.style.marginTop = 0;
			target.style.marginBottom = 0;
			target.offsetHeight;
			target.style.boxSizing = "border-box";
			target.style.transitionProperty = "height, margin, padding";
			target.style.transitionDuration = duration + "ms";
			target.style.height = height + "px";
			target.style.removeProperty("padding-top");
			target.style.removeProperty("padding-bottom");
			target.style.removeProperty("margin-top");
			target.style.removeProperty("margin-bottom");
			window.setTimeout(() => {
				target.style.removeProperty("height");
				target.style.removeProperty("overflow");
				target.style.removeProperty("transition-duration");
				target.style.removeProperty("transition-property");
				this.isOpen = true;
				this.$emit("endSlideDown");
			}, duration);
		},
		slideToggle: function (duration = 500) {
			let target = this.$el;
			if (window.getComputedStyle(target).display === "none") {
				return this.slideDown(duration);
			} else {
				return this.slideUp(duration);
			}
		},
	},
};
</script>

