<template>
	<div class="fullScreen">
		<div v-if="connected" id="authenticate">
			<form v-if="!showStayLoggedInDialog" class="login boxShadow">
				<h4>{{ $t("welcome") }}</h4>
				<Input
					ref="username"
					class="input"
					:label="$t('username')"
					v-model="userId"
					:autocomplete="credentialAPI ? 'off' : 'username'"
					:notification="userError"
					@click="withCredentials"
					@enter="onLoginClick"
				/>
				<Input
					class="input"
					:label="$t('password')"
					type="password"
					:autocomplete="credentialAPI ? 'off' : 'current-password'"
					v-model="password"
					:notification="pwError"
					@enter="onLoginClick"
				/>
				<Button class="submit" @click="onLoginClick" :loading="loading">Login</Button>
			</form>
			<div v-if="showStayLoggedInDialog" class="stayLoggedInDialog">
				<h1>{{ userFirstName }},</h1>
				<p>{{ $t("stayLoggedinMsg1") }}</p>
				<p class="settingsRow">
					<span class="settingsIcon">
						<Icon :icon="icons.Contact" />
					</span>
					{{ $t("stayLoggedinMsg2") }}
				</p>
				<p class="infoRow">{{ $t("stayLoggedinMsg3") }}</p>
				<div class="row">
					<Button class="submit yes" @click="stayLoggedInClick(true)">{{ $t("yes") }}</Button>
					<Button class="submit" @click="stayLoggedInClick(false)">{{ $t("no") }}</Button>
				</div>
			</div>
		</div>
		<div v-if="!connected">connecting...</div>
	</div>
</template>

<script>
import eventBus from "@components/Portal/eventBus";
import Input from "@components/Form/Input";
import Button from "@components/Form/Button";
import fetch from "@components/Portal/fetch";
import Icon from "@components/Tools/Icon";
import { Contact } from "@icons/appFabric/icons";

export default {
	name: "Authenticate",
	components: {
		Input,
		Button,
		Icon,
	},
	data: function () {
		return {
			userId: undefined,
			password: undefined,
			userError: undefined,
			pwError: undefined,
			showStayLoggedInDialog: false,
			connected: false,
			loading: false,
			userFirstName: undefined,
			icons: {
				Contact,
			},
			credentialAPI: null,
			gotoPath: null,
		};
	},
	methods: {
		/**
		 * Login submit
		 */
		onLoginClick: async function () {
			this.loading = true;

			//check values
			if (!this.userId || this.userId.length < 5) return;
			if (!this.password || this.password.length < 2) return;

			//try to fetch
			try {
				var loginResponse = await fetch.login(this.userId, this.password);
			} catch (error) {
				this.loading = false;
				this.onUnauthorized(error.message);
				//do not proceed to login
				return;
			}
			this.userError = null;
			this.pwError = null;

			this.userFirstName = loginResponse.userConfig.firstName;

			//set credentials
			if (this.credentialAPI) {
				let credName = loginResponse.userConfig.firstName + " " + loginResponse.userConfig.lastName;
				await this.storeCredentials(this.userId);
			}

			//show stayLoggedIn
			//console.log("showStayLoggedInDialog", typeof loginResponse.clientConfig.stayLoggedIn);
			this.showStayLoggedInDialog = typeof loginResponse.clientConfig.stayLoggedIn === "undefined" ? true : false;

			// if minimaster enabled => save pw
			if (loginResponse.userApps.minimaster) {
				this.$globalStatics.minimaster = {
					password: this.password,
				};
			}

			this.loading = false;
			if (this.showStayLoggedInDialog === false) {
				//unlock the app
				eventBus.emit("auth.authenticated", true);
			}
		},
		/**
		 * Login
		 */
		onUnauthorized: async function (message) {
			if (message == "User not found") {
				this.userError = this.$t("userNotFound");
				this.pwError = undefined;
			} else if (message == "User inactive") {
				this.userError = this.$t("userInactive");
				this.pwError = undefined;
			} else if (message == "Authentication failed") {
				this.userError = undefined;
				this.pwError = this.$t("passwordInvalid");
			} else {
				this.userError = undefined;
				this.pwError = undefined;
			}
		},
		stayLoggedInClick: async function (value) {
			eventBus.emit("auth.stayLoggedIn", value);
			this.$put(`user/stayLoggedIn/${value}`);
			//unlock the app
			eventBus.emit("auth.authenticated", true);
		},
		/**
		 * Get stored Credentials and set them to passwordfields
		 */
		withCredentials: async function () {
			console.log("withCredentials", window.PasswordCredential ? true : false);
			if (window.PasswordCredential) {
				const cred = await navigator.credentials.get({ password: true, mediation: "optional" });
				if (cred) {
					//this.$refs.username.blur();
					this.userId = cred.id;
					this.password = cred.password;
				}
			}
		},
		/**
		 * store credentials to browser store
		 */
		storeCredentials: async function (name) {
			//console.log("storeCredentials", window.PasswordCredential ? true : false, name);
			if (window.PasswordCredential) {
				const cred = new window.PasswordCredential({
					id: this.userId,
					password: this.password,
					name: name.trim(),
				});

				await navigator.credentials.store(cred);
			}
		},
		setLoginPath() {
			let pathParts = location.pathname.split("/").filter((item) => item);
			//login already at path end
			if (pathParts.length > 0 && pathParts[pathParts.length - 1] == "login") {
				pathParts.pop();
				this.gotoPath = "/" + pathParts.join("/");
				return;
			}
			//add login to path end
			this.gotoPath = "/" + pathParts.join("/");
			pathParts.push("login");
			let loginPath = "/" + pathParts.join("/");
			history.pushState({}, null, loginPath);
		},
	},
	created() {
		this.credentialAPI = this.$globalStatics.portalConfig.credentialAPI;
	},
	mounted: async function () {
		//remember calling path
		this.setLoginPath();
		//check to see if connection attempt was made
		if (fetch.connected === false) {
			await fetch.authorize();
		}
		this.connected = true;
		if (this.credentialAPI && fetch.accessJWT.hasExpired()) {
			this.withCredentials();
		}
	},
	beforeDestroy() {
		history.pushState({}, null, this.gotoPath);
	},
};
</script>

<i18n>
{
	"de": {
		"no": "Nein",
		"password": "Passwort",
		"passwordInvalid": "Passwort ungültig",
		"stayLoggedinMsg1": "Möchtest Du auf diesem Computer dauerhaft angemeldet bleiben?",
		"stayLoggedinMsg2": "Du findest die Einstellungen danach unter diesem Symbol",
		"stayLoggedinMsg3": "Durch Ausloggen oder Ändern der Einstellungen wird das angemeldet bleiben zurückgesetzt",
		"userInactive": "Benutzer inaktiv",
		"userNotFound": "Benutzer unbekannt",
		"username": "Benutzername",
		"welcome": "Willkommen",
		"yes": "Ja"
	},
	"fr": {
		"no": "Non",
		"password": "Mot de passe",
		"passwordInvalid": "Mot de passe non valable",
		"stayLoggedinMsg1": "Rester connecté en permanence sur cet ordinateur?",
		"stayLoggedinMsg2": "Cette icône permet d’accéder aux paramètres par la suite",
		"stayLoggedinMsg3": "La déconnexion ou une modification des paramètres annule le réglage «rester connecté».",
		"userInactive": "Utilisateur inactif",
		"userNotFound": "Utilisateur inconnu",
		"username": "Nom d’utilisateur",
		"welcome": "Bienvenue",
		"yes": "Oui"
	},
	"it": {
		"no": "No",
		"password": "Password",
		"passwordInvalid": "Password non valida",
		"stayLoggedinMsg1": "Vuoi rimanere collegato a questo computer in modo permanente?",
		"stayLoggedinMsg2": "Trovi le impostazioni sotto a questo simbolo",
		"stayLoggedinMsg3": "Eseguendo il logout o modificando le impostazioni, questa impostazione si azzera.",
		"userInactive": "Utente inattivo",
		"userNotFound": "Utente sconosciuto",
		"username": "Nome utente",
		"welcome": "Benvenuto",
		"yes": "Sì"
	},
	"en": {
		"no": "No",
		"password": "Password",
		"passwordInvalid": "Password invalid",
		"stayLoggedinMsg1": "Do you want to stay logged in?",
		"stayLoggedinMsg2": "You will find the settings later on by clicking on this Icon",
		"stayLoggedinMsg3": "If you log out, or change these settings, stay logged in will be reset",
		"userInactive": "User inactive",
		"userNotFound": "User unknown",
		"username": "Username",
		"welcome": "Wellcome",
		"yes": "Yes"
	}
}
</i18n>

<style scoped>
	h4 {
		margin-top: 32px;
		font-size: 37px;
		margin-bottom: 20px;
	}
	.fullScreen {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}
	.login {
		width: 350px;
		padding: 10px 50px;
	}
	.input {
		width: 100%;
		margin: 18px 0;
	}
	.input >>> .notification {
		color: red;
	}
	.login .submit {
		margin-top: 20px;
		margin-bottom: 50px;
		background-color: var(--apleona-blau2);
		color: white;
		height: 40px;
	}
	.login .submit:hover {
		background-color: var(--apleona-blau1);
		color: white;
	}
	.stayLoggedInDialog {
		border: 1px solid var(--lightGrey);
		width: 380px;
		padding: 10px 25px;
	}
	.stayLoggedInDialog .submit {
		margin-top: 30px;
		margin-bottom: 30px;
		height: 35px;
		width: 125px;
	}
	.stayLoggedInDialog .submit.yes {
		color: white;
		background-color: var(--apleona-grau1);
	}
	.stayLoggedInDialog .row {
		display: flex;
		justify-content: space-between;
	}
	.stayLoggedInDialog h1 {
		margin-bottom: 12px;
	}
	.settingsRow {
		margin-top: 24px;
		display: flex;
		align-items: flex-start;
	}
	.settingsRow .settingsIcon {
		border-radius: 50%;
		border: 1px solid grey;
		margin-bottom: 3px;
		min-width: 25px;
		min-height: 25px;
		margin-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 5px;
	}
	.infoRow {
		margin-top: 15px;
	}
</style>