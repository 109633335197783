import shortid from "shortid"
import { Group, Globe } from "../../@icons/appFabric/icons"
const minimaster = () => import(/* webpackChunkName: "Minimaster" */"./MinimasterApp.vue")
const appName = 'minimaster'

export default {
    name: appName,
    auth: true, //auth: if authenticated must have app in userApps
    menuShow: false, // always show
    menuPosition: 6, //sorting menu position of app
    component: minimaster,
    routes: [
        {
            name: "minimaster",
            path: "/minimaster"
        }
    ],
    menu: [
        {
            "_id": shortid.generate(),
            "appName": appName,
            "text": "Minimaster",
            "class": "minimaster",
            "route": "/minimaster", //route path
            "action": 'route', //action emitted to app
            "show": true,
            "icon": Group,
            value: 'minimaster'
        },
        {
            "_id": shortid.generate(),
            "appName": appName,
            "text": "ICC",
            "class": "minimaster",
            "route": "/minimaster", //route path
            "action": 'route', //action emitted to app
            "show": function (userApp, portalApp) {
                return portalApp.icc ? true : false
            },
            "icon": Globe,
            value: 'icc'
        }
    ],
    icc: false
}